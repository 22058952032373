<template>
  <div class="container">
    <h1>Subscribers</h1>

    <!-- Adding an Alert component to clarify a creation of subsciber error -->
    <alertOne v-if="vis"></alertOne> 

    <!-- Form to add the subscriber using submit button -->
    <form @submit.prevent="addSubscriber" class="add">
      <input 
        v-model="name" 
        placeholder="Name" 
        required
        class="text"
      />
      <input 
        v-model="email" 
        placeholder="Email" 
        required
        class="text"
      />
      <button type="submit" class="submit-button">Add Subscriber</button>
    </form>
    
    <!-- Dynamic list that loops throught the Subscribers Json array -->
    <ul class="subscriber-list">
      <li v-for="item in subscribers" :key="item.EmailAddress" class="subscribers">
        <span>{{ item.Name }} ({{ item.EmailAddress }})</span>

        <!-- Remove subscriber button calling the "removeSubscriber" function -->
        <button @click="removeSubscriber(item.EmailAddress)" class="remove">Remove</button>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import alertOne from './alertOne.vue';

export default {

  components:{
    alertOne
  },
 
  data() {
    return {
      name: '',
      email: '',
      subscribers: [],
      vis: false
    };
  },
  methods: {

    //Function for the Get List Request using axios
    async fetchSubscribers() {
      try {
        const response = await axios.get('/subscribers');
        this.subscribers = response.data.Results;
      } catch (error) {
        console.error('Error fetching subscribers', error);
      }
    },

    //Function for the Post Subscriber Request using axios
    async addSubscriber() {
      try {
        await axios.post('/subscriber', {
          name: this.name,
          email: this.email,
        });

        //Dynamicaly updating the subscribers JSON Array
        this.subscribers.push({
          "EmailAddress": this.email,
            "Name": this.name
        })
        this.name = '';
        this.email = '';
        
      } catch (error) {
        console.error('Error adding subscriber', error);

        //Flag for alert to appear when error occurs
         this.vis = true
        
      }
    },
    //Function for the Delete Subscriber Request using axios
    async removeSubscriber(email) {
      try {
        await axios.delete(`/subscriber/${email}`);

        //Dynamicaly deleting an entry from the subscribers JSON Array using the email as the index identifier
        this.subscribers.splice(email, 1)
      } catch (error) {
        console.error('Error removing subscriber', error);
      }
    },
  },

  //Calling the fetchSubscribers function on page load
  created() {
    this.fetchSubscribers();
  },
};
</script>



<style scoped>

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.add {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.text {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #4c77af;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

.subscriber-list {
  list-style-type: none;
  padding: 0;
}

.subscribers {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.remove {
  background-color: #cc1609;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove:hover {
  background-color: #e41d1d;
}
</style>